exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flappy-defender-index-js": () => import("./../../../src/pages/flappy-defender/index.js" /* webpackChunkName: "component---src-pages-flappy-defender-index-js" */),
  "component---src-pages-flappy-defender-privacy-policy-js": () => import("./../../../src/pages/flappy-defender/privacy_policy.js" /* webpackChunkName: "component---src-pages-flappy-defender-privacy-policy-js" */),
  "component---src-pages-flappy-defender-terms-and-conditions-js": () => import("./../../../src/pages/flappy-defender/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-flappy-defender-terms-and-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metra-business-index-js": () => import("./../../../src/pages/metra-business/index.js" /* webpackChunkName: "component---src-pages-metra-business-index-js" */),
  "component---src-pages-metra-business-privacy-policy-js": () => import("./../../../src/pages/metra-business/privacy_policy.js" /* webpackChunkName: "component---src-pages-metra-business-privacy-policy-js" */),
  "component---src-pages-metra-business-terms-and-conditions-js": () => import("./../../../src/pages/metra-business/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-metra-business-terms-and-conditions-js" */)
}

